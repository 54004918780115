export const projects = [
    {
        'description': 'Construction Of Multiple Dams Located All Over Tanzania Including Handeni, Chunya, Songea And Mtwara Just To Mention A Few.'
    },
    {
        'description': 'Multiple Roads Construction Including City And Village Roads All Over Tanzania Including Dar Es Salaam, Lindi, Tanga, Singida, Morogoro, Mbeya And Songea Just To Mention A Few.'
    },
    {
        'description': 'Clearance Of Over 4 Farms Including In Regions Such As Songea, Kisarawe And Dodoma.'
    },
    {
        'description': 'Partnered With TRL On The Reconstruction Of The Affected Railways (Due To Flooding) For Over Four Years.'
    },
    {
        'description': 'Built Trenches In Roads In Different Locations Including Dar Es Salaam (Such As Kigamboni, Kinondoni And Ukonga), Mtwara, Dodoma, Morogoro, Kilimanjaro.'
    },
    {
        'description': 'Contractors For New Luika Gold Mine (Shanta) - Tanzania In Which We Participated In Lauding & Hauling For Both Surface And Underground Mining Operations.'
    },
    {
        'description': 'Contractors For Ngaka Coal Mine (Tancoal Energy) - Tanzania In Which We Participated In Lauding & Hauling For Their Surface Mining Operations.'
    },
    {
        'description': 'Contractors For StamiGold - Tanzania In Which We Participated In Drilling, Lauding & Hauling For Their Surface Mining Operations.'
    },
]